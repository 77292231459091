import { appGoBack} from '../commonActions';

import * as DISPATCH_STATE from './supportChangeState';
// import * as LOG_EVENT from '../../../analytics';

/**
 * Toggle web widget zendesk
 * @param {*} script 
 */
export const toggleWebWidget = () => {
  return (dispatch, getState) => {
	  window.zE('webWidget', 'show');
	  window.zE('webWidget', 'open');
    // window.zE('webWidget', 'prefill', {
    //   name: {
    //     value: getState().common.client.givenName,
    //     // readOnly: true // optional
    //   },
    //   email: {
    //     value: getState().common.client.emailAddress,
    //     // readOnly: true // optional
    //   },
    //   phone: {
    //     value: getState().common.client.mobileNumber.fullNumber,
    //     // readOnly: true // optional
    //   }
    // });
    // window.zE("webWidget", "updateSettings", {
    //   webWidget: {
    //     chat: {
    //       departments: {
    //         select: "Support",
    //       },
    //     },
    //   },
    // });
    // window.zE('webWidget', 'toggle');
    dispatch(DISPATCH_STATE.supportWebWidgetToggle());
    // LOG_EVENT.logEvent(LOG_EVENT.CHAT_SUPPORT_OPENED_CWA);
  }
};
/**
 * Toggle web widget zendesk without client
 * @param {*} script 
 */
export const toggleWebWidgetWithoutClient = () => {
  return (dispatch, getState) => {
	  window.zE('webWidget', 'show');
	  window.zE('webWidget', 'open');
    // if(getState().common.auth && getState().common.authCheck) {
    //   window.zE('webWidget', 'prefill', {
    //     name: {
    //       value: getState().common.client.givenName
    //     },
    //     email: {
    //       value: getState().common.client.emailAddress
    //     },
    //     phone: {
    //       value: getState().common.client.mobileNumber.fullNumber
    //     }
    //   });
    // }
    // window.zE("webWidget", "updateSettings", {
    //   webWidget: {
    //     chat: {
    //       departments: {
    //         select: "Support",
    //       },
    //     },
    //   },
    // });
    // window.zE('webWidget', 'toggle');

    dispatch(DISPATCH_STATE.supportWebWidgetToggle());
    // LOG_EVENT.logEvent(LOG_EVENT.CHAT_SUPPORT_OPENED_CWA);
  }
};
/**
 * Toggle web widget zendesk and set Here2Help Department
 * @param {*} script 
 */
export const toggleFreeConciergeClient = () => {
	return (dispatch, getState) => {
		window.zE('webWidget', 'show');
		window.zE('webWidget', 'open');
		// if (getState().common.auth && getState().common.authCheck) {
		// 	window.zE("webWidget", "prefill", {
		// 		name: {
		// 			value: getState().common.client.givenName,
		// 		},
		// 		email: {
		// 			value: getState().common.client.emailAddress,
		// 		},
		// 		phone: {
		// 			value: getState().common.client.mobileNumber.fullNumber,
		// 		},
		// 	});
		// }
		// window.zE("webWidget", "updateSettings", {
		// 	webWidget: {
		// 		chat: {
		// 			departments: {
		// 				select: "Here2Help",
		// 			},
		// 		},
		// 	},
		// });
		// window.zE("webWidget", "toggle");

		dispatch(DISPATCH_STATE.supportWebWidgetToggle());
		// LOG_EVENT.logEvent(LOG_EVENT.CHAT_SUPPORT_OPENED_CWA);
	};
};
/**
 * Toggle web widget zendesk without client
 * @param {*} script 
 */
export const closeSupportWidget = () => {
  return (dispatch, getState) => {
    dispatch(DISPATCH_STATE.supportWebWidgetCloseByBack());
    window.zE('webWidget', 'close');
  	window.zE('webWidget', 'hide');
  }
};
/**
 * Set close listener just once
 */
export const setCloseListener = () => {
  return (dispatch, getState) => {
    if (!getState().support.initCloseLinstener) {
      dispatch(DISPATCH_STATE.initCloseLinstener());
      window.zE('webWidget:on', 'close', (e) => {
        if (!getState().support.closeByBack) {
          dispatch(appGoBack());
        }
      });
    }

  }
}
